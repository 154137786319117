<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Time-sheets</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" text to="/employee-timesheet/create">
          <v-icon left dark>mdi-plus</v-icon>New time-sheet
        </v-btn> -->
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="10">
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Search"
              single-line
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              small
              color="primary"
              class="ml-10 white --text"
              right
              @click="getTimeSheets"
              >search
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              dense
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="timeSheets"
              :search="search"
              item-key="id"
              :single-select="singleSelect"
            >
              <template v-slot:item.id="{ item }">
                <v-btn
                  color="primary"
                  text
                  :to="`/employee-timesheet/${item.id}`"
                  ><v-icon color="green">mdi-eye</v-icon></v-btn
                >
              </template>

              <template v-slot:item.Status="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeStatus(item)"
                  >{{ item.Status == 1 ? "Active" : "In Active" }}</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    timeSheets: [],
    loader: false,
    dialog: false,
    singleSelect: true,
    headers: [
      { text: "Name", value: "user.name" },
      { text: "Date", value: "Date" },
      { text: "ClockIn", value: "ClockIn" },
      { text: "ClockOut", value: "ClockOut" },
      { text: "Late", value: "Late" },
      { text: "EarlyLeaving", value: "EarlyLeaving" },
      { text: "Action", value: "id" },
    ],
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 50,
      page: 1,
    },
    totalItems: 0,
  }),
  watch: {
    options: {
      handler() {
        this.getTimeSheets();
      },
      deep: true,
    },
  },
  methods: {
    getTimeSheets() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch(
          "get",
          `/employee-timesheet?page=${this.options.page}&per_page=${this.options.itemsPerPage}&search=${this.search}`
        )
        .then((res) => {
          self.timeSheets = res.ResponseData.data;
          self.options.page = res.ResponseData.current_page;
          self.options.itemsPerPage = res.ResponseData.per_page;
          self.totalItems = res.ResponseData.total;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getTimeSheets();
  },
};
</script>